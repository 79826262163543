import { html } from 'lit';
import { BaseView } from './base';
export class ErrorView extends BaseView {
    constructor(props = ErrorView.defaultProps()) {
        super();
        this.props = props;
    }
    static defaultProps() {
        return { title: 'Error' };
    }
    async content() {
        return html `
      <ic-logo class="mb-3rem"></ic-logo>
      <ic-banner
        class="error"
        title="${this.props.title}"
        subtitle="${this.props.subtitle}"
      ></ic-banner>
    `;
    }
    static async load(props) {
        const view = new ErrorView(props);
        return view.render();
    }
}
