import { html } from 'lit';
import { BaseView } from './base';
export class UnsupportedView extends BaseView {
    constructor(props = UnsupportedView.defaultProps()) {
        super();
        this.props = props;
    }
    static defaultProps() {
        return {};
    }
    async content() {
        const title = 'This web browser cannot interact with the Internet Computer securely.';
        const subtitle = this.props.unsupported
            ? `Please try updating your browser or enabling support for ${this.props.unsupported}.`
            : `Please try updating your browser.`;
        return html `
      <ic-logo class="mb-3rem"></ic-logo>
      <ic-banner
        aria-label="${title} ${subtitle}"
        title="${title}"
        subtitle="${subtitle}"
      ></ic-banner>
    `;
    }
    static async load(props) {
        const view = new UnsupportedView(props);
        return view.render();
    }
}
