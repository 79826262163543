var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BannerElement = class BannerElement extends LitElement {
    constructor() {
        super(...arguments);
        this.title = 'Error';
        this.subtitle = '';
    }
    render() {
        if (this.subtitle) {
            return html `
        <div class="container">
          <h1>${this.title}</h1>
          <div role="doc-subtitle">${this.subtitle}</div>
        </div>
      `;
        }
        return html `
      <div class="container">
        <h1>${this.title}</h1>
      </div>
    `;
    }
};
BannerElement.styles = css `
    :host {
      display: block;
      padding: 0.75rem;
      margin: 0 16px;
      background-color: hsla(0, 0%, 100%, 0.2);
      border: transparent;
    }

    .container {
      border-color: transparent;
      padding: 2rem;
      background-color: hsla(0, 0%, 100%, 0.8);
    }

    h1 {
      font-size: 20px;
      line-height: 20px;
    }

    [role='doc-subtitle'] {
      margin-bottom: 1rem;
    }

    @media only screen and (max-width: 600px) {
      :host {
        margin: 0;
      }
    }
  `;
__decorate([
    property({ type: String })
], BannerElement.prototype, "title", void 0);
__decorate([
    property({ type: String })
], BannerElement.prototype, "subtitle", void 0);
BannerElement = __decorate([
    customElement('ic-banner')
], BannerElement);
export { BannerElement };
